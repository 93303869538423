import React, { useState } from 'react'
import './App.css'
import toast, { Toaster } from 'react-hot-toast'

const App = () => {
  const [query, setQuery] = useState('');
  const [colors, setColors] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (query === '') {
      toast('Enter some search value. Example: Google Colors')
      return
    }
    if (query.length > 30) {
      toast('Query too long. Example query: Google Colors')
      return
    }
    try {
      const response = await fetch("https://api-colors.gen-ai.varanbhalla.com/palette", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          query: query,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setColors(data.colors);
        toast('Colors Generated Successfully. Click color to copy its code')
      } else if (response.status === 429) {
        toast('Too many requests. Kindly try again later.');
      } else if (response.status === 500) {
        toast('Internal server error. Kindly try again later.');
      } else {
        toast('Something went wrong. Kindly try again later.')
      }
    } catch (error) {
      toast('Something went wrong. Kindly try again later.')
    }
  };

  const handleCopyToClipboard = (color) => {
    navigator.clipboard.writeText(color);
  };

  return (
    <div>
      <div className="container">
        {colors && colors.map((color, index) => (
          <div
            key={index}
            className="color"
            style={{ backgroundColor: color, width: `calc(100% / ${colors.length})` }}
            onClick={() => handleCopyToClipboard(color)}
          >
            <span>{color}</span>
          </div>
        ))}
      </div>
      <form id="form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Google"
        />
        <button className="btn" type="submit">Submit</button>
      </form>
      <Toaster />
    </div>
  )
}

export default App
